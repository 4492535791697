.navbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    background-color: #D9D9D9;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    z-index: 1000;
}

.arrow{
    margin-left: 5px;
    cursor: pointer;
}

.avatarContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    cursor: pointer;
    margin-right: 5px;
}